<template>
  <div class="top">
    <div class="visual-container">
      <confetti
        ref="visualConfetti"
        class="visual-confetti"
      />
      <h1
        :class="localeClass"
        class="visual-title"
      >
        <span class="sublogo">
          <img
            :src="require(`@images/top/${locale}/visual_sublogo.png`)"
            :alt="$t('top001')"
          >
        </span>
        <span class="logo">
          <img
            :src="require(`@images/top/${locale}/visual_logo.png`)"
            :alt="$t('top003')"
          >
        </span>
      </h1>
      <div class="visual-heroes">
        <img
          :src="visualHeroesImageSrc"
          width="750"
          alt=""
        >
      </div>
    </div>
    <div class="outline-box">
      <div class="box-inner">
        <p
          v-html="$t('top004')"
          class="text"
        />
        <div class="link-wrap">
          <about-link
            v-scroll-to="'#about'"
            href="#about"
          >
            {{ $t('top005') }}
          </about-link>
        </div>
        <p class="term">
          <span class="title" v-html="$t('top006')" /><br>
          <span class="content" v-html="$t('top007')" />
        </p>
      </div>
    </div>
    <div
      v-if="isResultOpen"
      ref="resultButton"
      class="result-container"
    >
      <div class="hero -left"></div>
      <div class="hero -right"></div>
      <div
        ref="twincles"
        class="twincle-container"
      >
        <span
          v-for="n of 21"
          :key="n"
          class="twincle"
        />
      </div>
      <animate-button
        tag="router-link"
        :to="{ name: 'Result' }"
        class="result-button"
      >
        <img
          :src="require(`@images/top/${locale}/result-btn_txt.png`)"
          alt=""
        >
      </animate-button>
    </div>
    <div class="vote-container">
      <theme-item
        v-for="(item, i) in themeList"
        :key="i"
        :theme="item"
        :isAnimeStart="voteAnimeStart"
        class="item"
      />
    </div>
    <about id="about" class="about-section" />
    <div class="page-footer">
      <sns-share
        shareText="common001"
        :replaceText="replaceText"
      />
      <app-download />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { sleep } from '@libraries/util/'
import { getRandomNumber } from '@libraries/util/Math'
import { mapActions, mapGetters } from 'vuex'
import Confetti from '@components/global/confetti.vue'
import ThemeItem from '@components/top/theme-item.vue'
import About from '@components/top/about.vue'
import AboutLink from '@components/top/about-link.vue'
import SnsShare from '@components/global/sns-share.vue'
import AppDownload from '@components/global/app-download.vue'
import AnimateButton from '@components/global/button-wrapper'

export default {
  name: 'Top',

  components: {
    Confetti,
    ThemeItem,
    About,
    AboutLink,
    SnsShare,
    AppDownload,
    AnimateButton
  },

  props: {
    locale: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      tls: {},
      voteAnimeStart: false
    }
  },

  computed: {
    ...mapGetters('loader', { loaderConfig: 'config' }),
    ...mapGetters('theme', { themeList: 'getThemes'}),
    ...mapGetters('time', { checkEventOpen: 'checkOpen' }),
    ...mapGetters('result', { isResultOpen: 'isOpen' }),

    localeClass () {
      return `-${this.locale}`
    },

    replaceText () {
      return { url: `${location.origin}/${this.locale}/` }
    },

    visualHeroesImageSrc () {
      const fileName =  this.checkEventOpen('top_visual') ? 'visual_heroes_post_lossless.png' : 'visual_heroes_lossless.png'
      return require(`@images/top/${fileName}`)
    }
  },

  mounted () {
    this.$nextTick(async () => {
      await sleep(this.loaderConfig.minDisplayDuration)
      this.hideLoader()
      this.run()
    })
  },

  destroyed () {
    Object.values(this.tls).forEach(t => t.kill())
  },

  methods: {
    ...mapActions('loader', { hideLoader: 'hide' }),

    run () {
      // this.$refs.visualConfetti.play()
      if (this.isResultOpen) {
        this.createResultAnimation()
        this.tls.resultHeroTl.play(0)
        this.tls.resultTwinclesTl.play(0)
      }
      this.voteAnimeStart = true// @FIXME メソッドを呼び出す
    },

    createResultAnimation () {
      const resultButton = this.$refs.resultButton

      this.tls.resultHeroTl = gsap.timeline({
        repeat: -1,
        repeatDelay: 1,
        paused: true
      })
      
      resultButton.querySelectorAll('.hero').forEach((heroElm, i) => {
        this.tls.resultHeroTl.add(this.createResultButtonHeroTl(heroElm, i), 0)
      })

      const twinclesElm = this.$refs.twincles
      this.tls.resultTwinclesTl = gsap.timeline({ paused: true })
      twinclesElm.querySelectorAll('.twincle').forEach((twincleElm) => {
        const duration = getRandomNumber(0.4, 0.6)
        const delay = getRandomNumber(0.3, 1.5)
        this.tls.resultTwinclesTl.add(
          this.createResultTwincleTween(twincleElm, duration),
          delay
        )
      })
    },

    createResultTwincleTween (twincleElm, duration) {
      return gsap.fromTo(twincleElm, {
        alpha: 0,
        scale: 0
      }, {
        alpha: 1,
        scale: 1,
        ease: 'quad.out',
        repeat: -1,
        yoyo: true,
        yoyoEase: 'quad.out',
        duration
      })
    },
    
    createResultButtonHeroTl (heroElm) {
      const stepDuration = 0.1
      const jumpDy = 20
      const tl = gsap.timeline()
      tl
        .to(heroElm, {
          y: `-=${jumpDy}`,
          duration: stepDuration,
          ease: 'sine.out'
        })
        .to(heroElm, {
          y: 0,
          duration: stepDuration,
          ease: 'sine.in'
        })
        .to(heroElm, {
          y: `-=${jumpDy}`,
          duration: stepDuration,
          ease: 'sine.out'
        })
        .to(heroElm, {
          y: 0,
          duration: stepDuration,
          ease: 'sine.in'
        })

      return tl
    }
  }
}
</script>

<i18n lang="yaml" src="@locales/top.yaml"></i18n>
<i18n lang="yaml" src="@locales/common.yaml"></i18n>

<style lang="scss" scoped>
.top {
  $top-z-index: (
    confetti: 0,
    visual: 2,
    outline: 3,
    result: 2
  );

  padding-top: 109px;

  .visual-container {
    background: url(#{$img-path}top/visual_bg_light.png) no-repeat center top;
    position: relative;
    width: 100%;
    height: 968px;
    margin-top: -4px;
    z-index: map-get($top-z-index, visual);
  }
    .visual-confetti {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 600px;
      z-index: map-get($top-z-index, confetti);
    }

    .visual-title {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;

      > .sublogo {
        margin-top: 55px;
        height: 111px;
      }
      > .logo {
        margin-top: 350px;
        height: 260px;
      }
    }
    .visual-heroes {
      position: absolute;
      top: 79px;
      z-index: 1;
    }

  .outline-box {
    position: relative;
    border-style: solid;
    border-width: 52px 80px;
    border-image: url(#{$img-path}box/goldbox_bg.png) 52 80 fill stretch;
    width: 722px;
    min-height: 443px;
    margin-top: -216px;
    margin-left: auto;
    margin-right: auto;
    z-index: map-get($top-z-index, outline);

    .box-inner {
      height: 100%;
      padding-top: 18px;
      padding-bottom: 18px;
      margin-left: -20px;
      margin-right: -20px;
    }
      .text {

      }
      .link-wrap {
        display: flex;
        justify-content: center;
      }
        .about-link {
          margin-top: 50px;
        }
      .term {
        margin-top: 25px;
        text-align: center;
        letter-spacing: 0.055em;
        > .title {

        }
        > .content {

        }
      }
  }

  .result-container {
    background: url(#{$img-path}top/bg_light2.png) center center;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 51px;
    padding-bottom: 80px;
    margin-top: -21px;
    margin-bottom: -101px;
    z-index: map-get($top-z-index, result);
    
    > .hero {
      pointer-events: none;
      position: absolute;
      top: 0;
      background-repeat: no-repeat;
      z-index: 10;

      &.-left {
        background-image: url(#{$img-path}top/hero1_lossless.png);
        top: 71px;
        left: -47px;
        width: 200px;
        height: 200px;
        transform-origin: 61% 50%;// 足を軸にするなら67%
      }
      &.-right {
        background-image: url(#{$img-path}top/hero2_lossless.png);
        top: 67px;
        right: -25px;
        width: 164px;
        height: 204px;
      }
    }

    > .result-button {
      position: relative;
      background: url(#{$img-path}top/result-btn.png) no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 714px;
      height: 244px;
      margin-left: auto;
      margin-right: auto;
    }
  }

    .twincle-container {
      pointer-events: none;
      position: absolute;
      top: 51px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 714px;
      height: 244px;
      z-index: 2;
      > .twincle {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        background: url(#{$img-path}common/twincle.png) no-repeat;
        width: 40px;
        height: 40px;

        &:nth-child(1) { left: -27px; top: 148px; }
        &:nth-child(2) { left: 704px; top: 11px; }
        &:nth-child(3) { left: 685px; top: 155px; }
        &:nth-child(4) { left: 21px; top: -2px; }
        &:nth-child(5) { left: 673px; top: 119px; }
        &:nth-child(6) { left: 673px; top: 119px; }
        &:nth-child(7) { left: 6px; top: 77px; }
        &:nth-child(8) { left: 80px; top: 147px; }
        &:nth-child(9) { left: 429px; top: -1px; }
        &:nth-child(10) { left: 188px; top: 123px; }
        &:nth-child(11) { left: 508px; top: 193px; }
        &:nth-child(12) { left: 543px; top: 124px; }
        &:nth-child(13) { left: 156px; top: 76px; }
        &:nth-child(14) { left: 166px; top: 196px; }
        &:nth-child(15) { left: 620px; top: 160px; }
        &:nth-child(16) { left: 610px; top: 27px; }
        &:nth-child(17) { left: 59px; top: 27px; }
        &:nth-child(18) { left: 220px; top: 2px; }
        &:nth-child(19) { left: 59px; top: 449px; }
        &:nth-child(20) { left: 543px; top: 0px; }
        &:nth-child(21) { left: 503px; top: 29px; }
      }
    }

  .vote-container {
    .item {
      margin: 108px 0;
    }
  }

  .about-section {
    margin-top: -33px;
  }
}
</style>