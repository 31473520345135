<template>
  <a class="about-link" @click="$emit('click', $event)">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'AboutLink',
}
</script>

<style lang="scss" scoped>
.about-link {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";
    display: block;
    width: 44px;
    height: 30px;
    background: url(#{$img-path}common/icon_arrow.png) no-repeat;
    margin-right: 11px;
    flex: 0 0 44px;
  }
}
</style>