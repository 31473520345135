<template>
  <section class="about">
    <h2 class="about-title">
      <img
        :src="require(`@images/about/${locale}/title.png`)"
        :alt="$t('top005')"
      >
    </h2>
    <div class="about-container">
      <about-link
        href="#terms"
        @click.prevent="showTerms()"
      >
        {{ $t('top036') }}
      </about-link>
    </div>
    <section class="theme-section">
      <h3 class="title">
        <img
          :src="require(`@images/about/${locale}/section_title1.png`)"
          :alt="$t('top037')"
        >
      </h3>
      <p class="text" v-html="$t('top038')" />
    </section>

    <section class="wallpaper-section">
      <h3 class="title" v-html="$t('top039')" />
      <div class="wallpaper-thumbs">
        <div class="thumb">
          <img
            src="@images/top/thumb_wallpaper_3times_lossless.png"
            alt=""
            width="150"
            height="267"
          >
        </div>
        <div class="thumb">
          <img
            src="@images/top/thumb_wallpaper_5times_lossless.png"
            alt=""
            width="150"
            height="267"
          >
        </div>
      </div>
    </section>

    <section class="point-section">
      <h3 class="title" v-html="$t('top040')" />
      <p class="notes" v-html="$t('top041')" />
      <div class="coin-box">
        <img
          src="@images/about/coin.png"
          alt=""
          width="178"
          height="182"
          class="img"
        >
        <p class="texts">
          <span
            v-html="$t('top042')"
            class="text1"
          />
          <span
            v-html="$t('top043')"
            class="text2"
          />
        </p>
      </div>
    </section>
    <div class="hr" />
    <a
      :href="twitterAccount"
      class="twitter-button"
      target="_blank"
    >
      <img
        :src="require(`@images/about/${locale}/tw-btn_txt.png`)"
        :alt="$t('top045')"
      >
    </a>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import AboutLink from '@/components/top/about-link.vue'

export default {
  name: 'About',
  components: {
    AboutLink,
  },
  computed: {
    locale () {
      return this.$route.params.lang
    },

    twitterLocaleId () {
      return this.locale === 'ja-jp' ? 'JP' : 'EN'
    },

    twitterAccount () {
      return `https://twitter.com/FE_Heroes_${this.twitterLocaleId}`
    },

    twitterGaAction () {
      return `link_twitter_${this.twitterLocaleId}`
    }
  },
  methods: {
    ...mapActions('terms', { showTerms: 'show' }),
  }
}
</script>

<i18n lang="yaml" src="@locales/top.yaml"></i18n>

<style lang="scss" scoped>
.about {
  position: relative;
  overflow: hidden;
  padding-bottom: 77px;

  > .hr {
    margin: 51px auto 45px;
  }
  > .twitter-button {
    margin-top: 53px;
    margin-left: auto;
    margin-right: auto;
  }
}
  .about-container {
    display: flex;
    justify-content: center;
    padding-left: 90px;
    padding-right: 90px;
  }

  .about-title {
    margin-bottom: 15px;
  }

  .about-link {
    position: relative;
    z-index: 5;
  }

  .theme-section {
    padding-top: 195px;
    margin-top: -167px;
    > .title {
      display: flex;
      justify-content: center;
      padding-left: 40px;
      padding-right: 40px;
    }
    > .text {
      font-size: $fs-middle;
      line-height: lh(40, $fs-middle);
      letter-spacing: -0.01em;
      padding-left: 90px;
      padding-right: 90px;
      margin-top: 22px;
    }
  }

  .wallpaper-section {
    margin-top: 56px;

    > .title {
      $fs: $fs-base * 1.8;
      font-size: $fs;
      font-weight: 600;
      line-height: lh(48, $fs);
      text-align: center;
      padding-left: 90px;
      padding-right: 90px;
    }
    > .wallpaper-thumbs {
      margin-top: -175px;
    }
  }
    .wallpaper-thumbs {
      background: url(#{$img-path}about/bg_light2.png) no-repeat center top;
      height: 724px;
      display: flex;
      justify-content: center;
      padding-top: 240px;
      > .thumb {
        width: 150px;
        height: 267px;

        &:nth-child(1) {
          transform: rotate(-15deg);
          box-shadow: -3px 9px 15px 0px rgba(171, 242, 255, 0.4);
        }
        &:nth-child(2) {
          transform: rotate(15deg);
          box-shadow: 5px 9px 15px 0px rgba(171, 242, 255, 0.4);
        }
      }
      > .thumb + .thumb {
        margin-left: 110px;
      }
    }

  .point-section {
    margin-top: -126px;

    > .title {
      $fs: $fs-base * 1.8;
      font-size: $fs;
      font-weight: 600;
      line-height: lh(48, $fs);
      text-align: center;
      letter-spacing: $ls-base;
      padding-left: 90px;
      padding-right: 90px;
      word-wrap: break-word;

      /deep/ a {
        text-decoration: underline;
        word-wrap: break-word;
      }
    }
    > .notes {
      margin-top: 23px;
      text-align: center;
      font-size: $fs-xsmall;
      line-height: lh(36, $fs-xsmall);
      letter-spacing: $ls-base;
      padding-left: 90px;
      padding-right: 90px;
    }
    > .coin-box {
      display: flex;
      width: 512px;
      height: 182px;
      padding-left: 6px;
      align-items: center;
      margin-top: 46px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 12px;

      > .img {
        margin-right: 48px;
        flex: 0 0 178px;
      }
      > .texts {
        margin-top: -14px;
        white-space: nowrap;
        > .text1 {
          font-size: $fs-xsmall;
          display: block;
        }
        > .text2 {
          display: block;
          $fs: $fs-base * 2.3;
          font-size: $fs;
          font-weight: 600;
          line-height: lh(36, $fs);
          margin-top: 14px;
        }
      }
    }
  }

  .hr {
    background: url(#{$img-path}about/hr.png) no-repeat center top;
    width: 616px;
    height: 34px;
  }

  .twitter-button {
    position: relative;
    background: url(#{$img-path}about/tw-btn_bg.png) no-repeat center top;
    width: 618px;
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      background: url(#{$img-path}about/tw-btn_obj_lossless.png) no-repeat 0 0;
      content: '';
      display: block;
      width: 164px;
      height: 122px;
      position: absolute;
      top: -16px;
      right: 11px;
      animation:
        anim-fe 0.5s steps(2) infinite,// 0.5で1羽ばたき 5回で片道 = 2.5s
        anim-hovering 5s $easeInOutSine infinite;

      @keyframes anim-fe {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: -328px 0;
        }
      }
      @keyframes anim-hovering {
        0% {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(-25px);
        }
        100% {
          transform: translateY(0px)
        }
      }
    }
  }
</style>