import { render, staticRenderFns } from "./theme-item.vue?vue&type=template&id=5a7dd182&scoped=true&"
import script from "./theme-item.vue?vue&type=script&lang=js&"
export * from "./theme-item.vue?vue&type=script&lang=js&"
import style0 from "./theme-item.vue?vue&type=style&index=0&id=5a7dd182&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a7dd182",
  null
  
)

/* custom blocks */
import block0 from "@/locales/top.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fcircleci%2Fworkspace%2Ffrontend%2Fsrc%2Fcomponents%2Ftop%2Ftheme-item.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports