<template>
  <section class="theme-item" :class="{'-ing': isOpen}">
    <vote-day class="vote-day">
      <img
        :src="require(`@images/common/${locale}/title_txt_day${theme.id}.png`)"
        :alt="title"
      />
    </vote-day>
    <section class="inner">
      <!--御光と紙吹雪-->
      <template v-if="isOpen">   
        <div v-if="isOpen" class="halo" />
        <confetti :height="570" />
      </template>

      <!-- 投票受付中 -->
      <div
        v-if="isOpen"
        ref="ing"
        class="vote-theme -ing"
      >
        <div class="hero -left" />
        <div class="hero -right" />
        <div
          ref="twincles"
          class="twincle-container"
        >
          <span
            v-for="n of 9"
            :key="n"
            class="twincle"
          />
        </div>
        <vote-theme
          :status="theme.status"
        >
          <span v-html="theme.name" />
        </vote-theme>
      </div>

      <!-- 受付中以外 -->
      <template v-else>
        <vote-theme
          :status="theme.status"
          class="vote-theme"
        >
          <span v-html="theme.name" />
        </vote-theme>
      </template>

      <div class="vote-term">
        <span
          v-html="$t('top020')"
          class="title"
        />
        <p
          v-html="voteTerm"
          class="text"
        />
      </div>

      <animate-button
        @click="clickVoteBtn()"
        :class="{ '-gold': theme.status !== 3, '-disabled': theme.status !== 1 }"
        class="vote-button"
      >
        <img
          :src="buttonTextSrc"
          :alt="buttonText"
        />
      </animate-button>
    </section>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters, mapActions } from 'vuex'
import { getRandomNumber } from '@libraries/util/Math'
import Confetti from '@components/global/confetti.vue'
import VoteDay from '@components/global/section-title.vue'
import VoteTheme from '@components/global/theme-title.vue'
import AnimateButton from '@components/global/button-wrapper'
import NaModal from '@components/modals/na-modal.vue'
import ModalModel from '@models/modal'

export default {
  name: 'VoteItem',

  components: {
    VoteDay,
    VoteTheme,
    Confetti,
    AnimateButton
  },

  props: {
    theme: {
      type: Object,
      required: true,
    },
    isAnimeStart: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      tls: {}
    }
  },

  watch: {
    // 実行タイミングの制御
    isAnimeStart (val) {
      if (this.theme.status !== 1 || !val) {
        return
      }
      this.animate()
    }
  },

  computed: {
    ...mapGetters('login', [ 'isLogin' ]),

    title () {
      const themeTypeText = this.theme.type === 'single' ?
        this.$t('top008') :
        this.$t('top009')
      const id = this.theme.id
      return this.$t(`top01${id-1}`, { [`theme${id}_type`]: themeTypeText })
    },

    voteTerm () {
      const id = this.theme.id
      return this.$t(`top02${id}`)
    },

    isOpen () {
      return this.theme.status === 1
    },

    locale () {
      return this.$route.params.lang
    },

    buttonTextSrc () {
      switch (this.theme.status) {
        case 1:
          return require(`@images/top/${this.locale}/vote-btn_txt.png`)
        case 2:
          return require(`@images/top/${this.locale}/vote-btn_txt_pre.png`)
        case 3:
          return require(`@images/top/${this.locale}/vote-btn_txt_end.png`)
      }
      return null
    },

    buttonText () {
      switch (this.theme.status) {
        case 1:
          return this.$t('top032')
        case 2:
          return this.$t('top034')
        case 3:
          return this.$t('top033')
      }
      return null
    }
  },

  mounted () {
    if (!this.isOpen) {
      return
    }

    const ingElm = this.$refs.ing
    this.tls.heroTl = gsap.timeline({
      repeat: -1,
      repeatDelay: 1,
      paused: true
    })
    ingElm.querySelectorAll('.hero').forEach((heroElm, i) => {
      this.tls.heroTl.add(this.createIngHeroTl(heroElm, i), 0)
    })

    const twinclesElm = this.$refs.twincles
    this.tls.resultTwinclesTl = gsap.timeline({ paused: true })
    twinclesElm.querySelectorAll('.twincle').forEach((twincleElm) => {
      const duration = getRandomNumber(0.4, 0.6)
      const delay = getRandomNumber(0.3, 1.5)
      this.tls.resultTwinclesTl.add(
        this.createIngTwincleTween(twincleElm, duration),
        delay
      )
    })

    // 開発環境の更新タイミング用
    if (this.isAnimeStart) {
      this.animate()
    }
  },

  destroyed () {
    Object.values(this.tls).forEach(t => t.kill())
  },

  methods: {
    ...mapActions('modal', { showModal: 'show'}),

    animate () {
      this.tls.heroTl.play(0)
      this.tls.resultTwinclesTl.play(0)
    },

    createIngTwincleTween (twincleElm, duration) {
      return gsap.fromTo(twincleElm, {
        alpha: 0,
        scale: 0
      }, {
        alpha: 1,
        scale: 1,
        ease: 'quad.out',
        repeat: -1,
        yoyo: true,
        yoyoEase: 'quad.out',
        duration
      })
    },

    createIngHeroTl (heroElm) {
      const stepDuration = 0.1
      const jumpDy = 20
      const tl = gsap.timeline()
      tl
        .to(heroElm, {
          y: `-=${jumpDy}`,
          duration: stepDuration,
          ease: 'sine.out'
        })
        .to(heroElm, {
          y: 0,
          duration: stepDuration,
          ease: 'sine.in'
        })
        .to(heroElm, {
          y: `-=${jumpDy}`,
          duration: stepDuration,
          ease: 'sine.out'
        })
        .to(heroElm, {
          y: 0,
          duration: stepDuration,
          ease: 'sine.in'
        })

      return tl
    },

    clickVoteBtn () {
      const params = {
        lang: this.locale,
        themeType: this.theme.id,
      }

      if (this.isLogin) {
        this.$router.push({name: 'Series', params})
      } else {
        const path = `/${this.locale}/theme/${this.theme.id}/series`
        const redirectTo = `${location.origin}${path}`
        this.showModal(new ModalModel({
          component: NaModal,
          props: {
            type: 'default',
            withoutLogin: path,
            redirectTo
          }
        }))
      }
    }
  }
}
</script>

<i18n lang="yaml" src="@/locales/top.yaml"></i18n>

<style lang="scss" scoped>
$theme-z-index: (
  vote-day: 2,
  confetti: 0,
  halo: 0,
  vote-theme: 3,
  vote-term: 3,
  vote-button: 3,
);

.theme-item {
  position: relative;
  min-height: 574px;
  background:
    url(#{$img-path}common/section_bg_symbol.png) no-repeat center 20px,
    url(#{$img-path}top/bg_theme_item.png) no-repeat;
  background-size: auto auto, cover;
  color: $color-sub;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    background-repeat: no-repeat;
  }
  &:before {
    top: 0;
    left: 0;
    height: 16px;
    background-image: url(#{$img-path}top/top_line_blue.png);
  }
  &:after {
    left: 0;
    bottom: -5px;
    height: 18px;
    background-image: url(#{$img-path}top/btm_line_blue.png);
  }

  &.-ing {
    background:
      url(#{$img-path}common/section_bg_symbol.png) no-repeat center 20px,
      url(#{$img-path}common/section_bg_repeat_a.png) repeat-y;
    color: $color-base;

    &:before {
      background-image: url(#{$img-path}top/top_line_gold.png);
    }
    &:after {
      background-image: url(#{$img-path}top/btm_line_gold.png);
    }
  }

  .vote-day {
    position: absolute;
    top: -72px;
    left: 0;
    width: 100%;
    z-index: map-get($theme-z-index, vote-day);
  }

  .inner {
    position: relative;
    overflow: hidden;
    height: 100%;
    padding-top: 105px;
    padding-bottom: 56px;
  }
    .confetti {
      position: absolute;
      top: 14px;
      pointer-events: none;
    }
    .halo {
      position: absolute;
      top: -200px;
      left: 0;
      background: url(#{$img-path}top/bg_light.png) center top;
      width: 100%;
      height: 836px;
      z-index: map-get($theme-z-index, halo);
      display: flex;
      align-items: center;
      pointer-events: none;
    }
    .vote-theme.-ing {
      position: relative;
      z-index: map-get($theme-z-index, vote-theme);
      .hero {
        pointer-events: none;
        position: absolute;
        background-repeat: no-repeat;
        z-index: 4;
        &.-left {
          background-image: url(#{$img-path}top/hero1_lossless.png);
          width: 200px;
          height: 200px;
          top: -43px;
          left: -47px;
          transform-origin: 61% 50%;
        }
        &.-right {
          background-image: url(#{$img-path}top/hero2_lossless.png);
          width: 164px;
          height: 204px;
          top: -45px;
          right: -25px;
        }
      }
      > .twincle-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 3;
        pointer-events: none;
      }
    }
      .twincle-container {
        width: 610px;
        height: 120px;

        > .twincle {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          background: url(#{$img-path}common/twincle.png) no-repeat;
          width: 40px;
          height: 40px;

          &:nth-child(1) { left: 95px;  top: 85px; }
          &:nth-child(2) { left: 105px; top: -35px; }
          &:nth-child(3) { left: 55px;  top: 5px; }
          &:nth-child(4) { left: 455px; top: -19px; }
          &:nth-child(5) { left: 524px; top: 41px; }
          &:nth-child(6) { left: 497px; top: 87px; }
          &:nth-child(7) { left: 388px; top: 88px; }
          &:nth-child(8) { left: 0px;   top: 0px; }
          &:nth-child(9) { left: 143px; top: 117px; }
        }
      }

    .vote-term {
      position: relative;
      margin-top: 53px;
      margin-bottom: 30px;
      padding-left: 40px;
      padding-right: 40px;
      text-align: center;
      letter-spacing: 0.025em;
      z-index: map-get($theme-z-index, vote-term);
    }

    .vote-button {
      position: relative;
      z-index: map-get($theme-z-index, vote-button);
      width: 460px;
      height: 114px;
      display: block;
      margin: 0 auto;
      background: url(#{$img-path}top/bg_votebtn_blue.png) no-repeat;
      padding-top: 4px;
      padding-bottom: 10px;

      &.-gold {
        background-image: url(#{$img-path}top/bg_votebtn_gold.png);
      }
      &.-disabled {
        pointer-events: none;
        img {
          opacity: .5;
        }
      }
    }
}
</style>
